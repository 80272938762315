<template>
  <div></div>
</template>

<script>
import { login } from "@/api/login";
export default {
  name: "login",
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {
    if (sessionStorage.access_id) {
      return;
    }
    if (!this.$route.query.code) {
      login({
        code: this.$route.query.code || "",
        callback: window.location.href
      }).then(res => {
        console.log(res);
        window.location.href = res.data.data.url;
      });
      return;
    }
    const res = await login({
      code: this.$route.query.code || ""
    }); /* .then(res => {
      console.log(res, "用户资料");
      console.log(res); */
    if (res.data.code == 200) {
      const { access_id, headimgurl, nickname, uid, description } =
        res.data.data.list;
      sessionStorage.access_id = access_id;
      sessionStorage.headimgurl = headimgurl;
      sessionStorage.nickname = nickname;
      sessionStorage.uid = uid;
      sessionStorage.description = description;
      /* setTimeout(() => {
        this.$refs.vantab.onLoad();
      }); */
      this.$toast({
        message: "登录成功",
        position: "middle",
        duration: 1500,
        onClose: () => {
          this.$router.replace("/huodong");
        }
      });
      /*  this.$toast("登录成功");
      this.$router.push("/huodong"); */
      /*  if (this.$route.query.redirect) {
        this.$router.push({ path: this.$route.query.redirect });
      } */
    }
    /*  }); */
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
