import { axios } from "./../plugins/axios";

export function login(data) {
  return axios.request({
    method: "POST",
    url: "/api/Login/wechatLogin",
    data: {
      ...data
    }
  });
}
